// import InformationBg from "@images/information/bg.png";
import Logo from "@images/information/logo.png";
import mobileBg from "@images/information/mobile_bg.png";
import mobileLogo from "@images/information/mobile_logo.svg";
import SuccessImg from "@images/information/success.png";
import { Button, Form, Input, Select } from "antd";
import { graphql, navigate } from "gatsby";
import React, { useState } from "react";
import Helmet from "react-helmet";
import * as styles from "./index.module.scss";

import { sendCustomInformation } from "@src/utils/register.js";
import { useEffect } from "react";

import ChinaLocation from "china-location";
import list from "china-location/dist/location.json";
const location = new ChinaLocation(list);

const { locationData } = location;

const year_turnover_array = [
  " 0-99万人民币",
  "100-499万人民币",
  "500-999万人民币",
  "1000-4999万人民币",
  "5000万人民币以上",
];

const goods_type_array = [
  "代工厂",
  "自有品牌",
  "货铺",
  "无资源(一键代发)",
  "其他",
];

const gotoIndex = () => {
  navigate("/");
};

const phoneReg = "^1[0-9]{10}$";

const Card = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [step2, setStep2] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isNextAvailable, setAvailable] = useState(false);
  const [phone, setPhone] = useState(false);
  const [name, setName] = useState(false);
  const nextStep = () => {
    setStep2(true);
  };

  const prevStep = () => {
    setStep2(false);
  };

  const onFinish = (value) => {
    sendCustomInformation(value).then((res) => {
      if (res && res.status === 200) {
        setSuccess(true);
      }
    });
  };
  const [citiesData, setCitiesData] = useState([
    {
      name: "东城区",
    },
    {
      name: "西城区",
    },
    {
      name: "朝阳区",
    },
    {
      name: "丰台区",
    },
    {
      name: "石景山区",
    },
    {
      name: "海淀区",
    },
    {
      name: "门头沟区",
    },
    {
      name: "房山区",
    },
    {
      name: "通州区",
    },
    {
      name: "顺义区",
    },
    {
      name: "昌平区",
    },
    {
      name: "大兴区",
    },
    {
      name: "怀柔区",
    },
    {
      name: "平谷区",
    },
    {
      name: "密云区",
    },
    {
      name: "延庆区",
    },
  ]);

  const handleProvinceChange = (provinceName) => {
    let province = Object.values(locationData).find((city) => {
      return city.name === provinceName;
    });
    let cities = Object.values(province.cities);
    console.log(cities);
    if (cities.length === 1 && cities[0].districts) {
      cities = Object.values(cities[0].districts).map((item) => {
        return { name: item };
      });
    }
    setCitiesData(cities);
    form.setFieldsValue({ city: cities[0].name });
  };

  const handleAddressChange = ({ target }) => {
    setAvailable(target.value.length);
  };
  const onNameChange = ({ target }) => {
    console.log(target.value);
    setName(target.value.length);
  };
  const onPhoneChange = ({ target }) => {
    setPhone(target.value.length);
  };
  useEffect(() => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/register/");
    }
    return () => {
      // sessionStorage.removeItem("token");
    };
  }, []);

  return (
    <div className={styles.card}>
      {!success ? (
        <>
          <div className={styles.title}>设置企业信息</div>
          <div className={styles.steps}>
            <div className={styles.step_active}>1/企业信息</div>
            <div className={step2 ? styles.step_active : styles.step}>
              2/联系人信息
            </div>
            <div className={styles.extra}>
              {step2
                ? "我们将向联系人发送审核信息"
                : "我们将根据您的企业信息推荐最佳方案"}
            </div>
          </div>
          <div className={styles.form}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              form={form}
              initialValues={{
                country: "中国",
                province: "北京市",
                city: "东城区",
              }}
            >
              <div style={{ display: step2 ? "none" : "block" }}>
                <div className={styles.address}>
                  <Form.Item
                    label="企业地址"
                    required
                    style={{ marginBottom: "0" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item name="country" style={{ width: "31%",marginBottom:"12px" }}>
                        <Select size="large">
                          <Option key="中国">中国</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item name="province" style={{ width: "31%",marginBottom:"12px" }}>
                        <Select onChange={handleProvinceChange} size="large">
                          {Object.values(locationData).map((province) => (
                            <Option key={province.name}>{province.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item name="city" style={{ width: "31%",marginBottom:"12px" }}>
                        <Select size="large">
                          {citiesData.map((city) => (
                            <Option key={city.name}>{city.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>

                    <Form.Item name="address">
                      <Input
                        size="large"
                        onChange={handleAddressChange}
                        placeholder="请输入详细地址"
                        maxLength={100}
                        style={{
                          borderRadius:"4px",
                          height:"48px"
                        }}
                      ></Input>
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className={styles.sales}>
                  <Form.Item label="您每年的销售额是？" name="year_turnover">
                    <Select size="large">
                      {year_turnover_array.map((item, index) => (
                        <Option key={index + 1}>{item}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className={styles.category}>
                  <Form.Item label="您销售的商品类目是？" name="goods_type">
                    <Select size="large">
                      {goods_type_array.map((item, index) => (
                        <Option key={index + 1}>{item}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                <Button
                  type="primary"
                  style={{
                    height: "48px",
                    borderRadius: "4px",
                    border: "none",fontSize:"16px",
                    color:"#fff",
                    marginTop:"10px"
                  }}
                  disabled={!isNextAvailable}
                  block
                  onClick={nextStep}
                >
                  下一步
                </Button>
              </div>
              <div style={{ display: step2 ? "block" : "none" }}>
                <div className={styles.name}>
                  <Form.Item
                    label="联系人姓名"
                    required
                    name="name"
                    rules={[{ required: true, message: "请输入联系人姓名" }]}
                  >
                    <Input
                      placeholder="请输入联系人姓名"
                      size="large"
                      onChange={onNameChange}
                      maxLength={100}
                      style={{
                        borderRadius:"4px",
                        height:"48px"
                      }}
                    />
                  </Form.Item>
                </div>
                <div className={styles.phone}>
                  <Form.Item
                    label="联系号码"
                    name="phone"
                    required
                    onChange={onPhoneChange}
                    rules={[
                      {
                        pattern: new RegExp(phoneReg),
                        message: "请输入正确的电话号码",
                      },
                      {
                        required: true,
                        message: "请输入电话号码",
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入联系人手机号码"
                      size="large"
                      maxLength={100}
                      style={{
                        borderRadius:"4px",
                        height:"48px"
                      }}
                    />
                  </Form.Item>
                </div>

                <Form.Item style={{marginTop:"10px"}}>
                  <Button
                    style={{
                      width: "38%",
                      height: "48px",
                      borderRadius: "4px",
                      background: "#E9F0FF",
                      color: "#0075FF",
                      border: "none",
                      fontSize:"16px",
                    }}
                    onClick={prevStep}
                  >
                    返回上一步
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "60%",
                      height: "48px",
                      marginLeft: "2%",
                      borderRadius: "4px",
                      border: "none",
                      fontSize:"16px",
                      color:"#fff"
                    }}
                    disabled={!(phone && name)}
                  >
                    提交
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </>
      ) : (
        <div className={styles.success}>
          <img
            src={SuccessImg}
            alt="successImg"
            className={styles.success_img}
          />
          <div className={styles.text}>
            <h1>提交成功</h1>
            <p>
              感谢您申请注册，我们已收到您的资料。
              <br />
              申请结果将在近期通过手机短信/邮件进行通知，请注意查看。
            </p>
          </div>
          <Button
            type="primary"
            size="large"
            style={{ height: "48px", width: "100%", borderRadius: "4px",color:"#fff" }}
            onClick={gotoIndex}
          >
            返回首页
          </Button>
        </div>
      )}
    </div>
  );
};

const Information = ({data}) => {
  const backgroundImg=data.BG.childImageSharp.fluid.srcWebp;
  return (
    <div
      className={styles.information}
      style={{ background: `url(${backgroundImg})` }}
    >
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,user-scalable=0"
        />
        <title>用户信息收集-Shoprise 官网</title>
      </Helmet>
      <div className={styles.logo}>
        <img src={Logo} alt="logo" onClick={gotoIndex} />
      </div>
      <div className={styles.mobile_show}>
        <img src={mobileBg} alt="" width="100%" />
        <img
          src={mobileLogo}
          alt="mobileLogo"
          className={styles.mobile_logo}
          onClick={gotoIndex}
        />
      </div>
      <div className={styles.card_container}>
        <Card />
      </div>
    </div>
  );
};

export default Information;

const SEO = () => {
  return (
    <>
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,user-scalable=0"
      />
    </>
  );
};

export const Head = () => {
  return <SEO></SEO>;
};

export const pageQuery = graphql`
  query {
   BG:file(relativePath: {eq: "information/bg.png"}) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
         ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
