// extracted by mini-css-extract-plugin
export var address = "index-module--address--KXXW2";
export var card = "index-module--card--Sq4f5";
export var card_container = "index-module--card_container--mbW7r";
export var category = "index-module--category--1n-g6";
export var extra = "index-module--extra---UdoB";
export var form = "index-module--form--chj2I";
export var information = "index-module--information--6pnjB";
export var logo = "index-module--logo--4E3ef";
export var mobile_logo = "index-module--mobile_logo--CUdzn";
export var mobile_show = "index-module--mobile_show--o2rrp";
export var name = "index-module--name--e6gTg";
export var phone = "index-module--phone--pmWoe";
export var sales = "index-module--sales--gUp3k";
export var step = "index-module--step--Ovdg9";
export var step_active = "index-module--step_active--ewdwj";
export var steps = "index-module--steps--EkiGo";
export var success = "index-module--success--lXXY+";
export var success_img = "index-module--success_img--je-Gf";
export var text = "index-module--text--IYdXN";
export var title = "index-module--title--U6L8e";